import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/header"
import faviconIco from "../images/favicon.ico"

export default function Layout(thing) {
  return(	  
  	<div className="application">
  		<Helmet>
  			<title>Bits o’ stuff from Jacob Hodes</title>
			<link rel="icon" href={faviconIco} />
			<link rel="icon" href="/images/favicon.icns" />  { /*Gatsby doesn't seem to want to import .icns files? */ }
			<link href="https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,615;0,700;0,800;1,400;1,500;1,600;1,615;1,700;1,800&display=swap" rel="stylesheet" />
			<script src="https://kit.fontawesome.com/b7e4af91e5.js" crossorigin="anonymous"></script>
		</Helmet>
	    <Header />
		{thing.children}  
	</div>
	)
}
