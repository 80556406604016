import React from "react"
import { Link } from "gatsby"

export default function Header(props) {
return (		
		<div className="container">
			<div className="row topnav">							
				<div className="col d-flex justify-content-center">
					<div className="container topnav-inner" style={{width: "720px"}}>
						<div className="row">
							<div className="col">
								<Link to="/"><span>h</span>ome</Link>		
								{/*<Link to="/#tags"><span>t</span>ags</Link>*/}
								{/*<Link to="#search"><span>s</span>earch</Link>*/}
								<Link to="/about">about</Link>
								<Link to="/7mw">7mw</Link>
							</div>
						</div>
					</div>
				</div>									
			</div>
		</div>		
	)
}
